@font-face {
  font-family: "MontserratRegular";
  src: url(Montserrat-Regular.ttf) format("ttf"),
    url(Montserrat-Regular.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BlackOpsOneRegular";
  src: url(BlackOpsOne-Regular.ttf) format("ttf"),
    url(BlackOpsOne-Regular.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Black+Ops+One&family=Grape+Nuts&family=Oswald&family=Roboto:wght@700&display=swap');
